
import HttpMixin from '@/shared/http/http.mixin';
import WithSiteMixin from '@/shared/sites/mixins/with-site.mixin';
import { EslConfig } from '@/shared/store/models/EslConfig';
import WithSnackMixin from '@/shared/app/mixins/with-snack.mixin';
import Component, { mixins } from 'vue-class-component';
import BaseForm from '../_BaseForm.vue';
import EslSelectionForm from './extensions/EslSelectionForm.vue';
import EslEditionCard from './extensions/EslEditionCard.vue';
import EslCloningCard from './extensions/EslCloningCard.vue';
import { Watch } from 'vue-property-decorator';
import { EslInfo } from '@/shared/store/models/EslInfo';
import { Nullable } from '@/shared/app/app.utils';
import { SnackType } from '@/shared/app/app.constants';
import { AxiosError } from 'axios';

@Component({
  components: { EslSelectionForm, EslEditionCard, EslCloningCard },
})
export default class EslForm extends mixins(BaseForm, WithSiteMixin, HttpMixin, WithSnackMixin) {

  eslConfigs: EslConfig[] = [];
  eslInfos: EslInfo[] = [];
  eslValue: Nullable<{ eslConfig: EslConfig; eslInfo: Nullable<EslInfo>; isChild: boolean; parentUseCode: Nullable<string> }> = null;
  eslInfo: Nullable<EslInfo> = null;
  eslConfig: Nullable<EslConfig> = null;

  async activate() {
    this.reset();
    this.emitLoading(true);
    await this.loadEslConfigs();
    this.emitLoading(false);
  }

  // Chargement des étiquettes de ce site
  async loadEslConfigs() {
    if (this.site) {
      let hasChanged = false;
      this.eslConfigs = await this.http.loadEslConfigs(this.site?.customerId);

      this.loadCachedEslInfo(this.site?.customerId);

      for (let i = 0; i < this.eslConfigs.length; i++) {
        const eslConfig = this.eslConfigs[i];
        if (!this.eslInfos.find(i => i.labelCode === eslConfig.labelCode)) {
          try {
            const eslinfo = await this.http.getEslInfo(eslConfig.labelCode);
            this.eslInfos.push(eslinfo);
            if (!hasChanged) hasChanged = true;
          } catch (e) {
            console.error(e);
          }
        }
      }

      if (this.eslConfigs.length > 25) {
        this.snack({
          text: `Chargement terminé, veuillez sélectionner une étiquette pour afficher ses informations.`,
          // timeout: 8000,
          type: SnackType.INFO,
        });
      }

      if (hasChanged) this.save();
    }
  }

  loadCachedEslInfo(customerId: string) {
    // Load eslInfos from localStorage
    if (localStorage.getItem(`${customerId ?? ''}_eslInfos`)) {
      try {
        console.info('Loading', `${customerId ?? ''}_eslInfos`);
        this.eslInfos = JSON.parse(localStorage.getItem(`${customerId ?? ''}_eslInfos`) ?? '[]');
      } catch(e) {
        console.error(e);
        localStorage.removeItem(`${customerId ?? ''}_eslInfos`);
      }
    } else if (this.eslConfigs.length > 25) {
        this.snack({
          text: `Cette opération peut durer quelques minutes lors de la première initialisation, veuillez patienter...`,
          // timeout: 8000,
          type: SnackType.INFO,
        });
      }
  }

  save() {
    if(!this.eslInfos.length) return;
    const parsed = JSON.stringify(this.eslInfos);
    console.info('Saving', `${this.site?.customerId ?? ''}_eslInfos`, this.eslInfos.length, 'labels');
    localStorage.setItem(`${this.site?.customerId ?? ''}_eslInfos`, parsed);
  }

  @Watch('eslValue')
  async selectionWatcher() {
    if (!this.eslValue) return;
    this.eslConfig = this.eslValue.eslConfig;
    // On doit actualiser les infos en tps réel
    this.emitLoading(true);
    this.eslInfo = null;
    await this.http.getEslInfo(this.eslConfig.labelCode).then(
      (eslInfo) => {
        this.eslInfo = eslInfo;
        // update eslInfo in eslInfos
        this.eslInfos = this.eslInfos.filter(i => i.labelCode !== eslInfo.labelCode);
        this.eslInfos.push(eslInfo);
        this.save();
      },
      (e: AxiosError) => {
        this.snack({
          text: e.response?.data.message || `Erreur inconnue (${e.response?.data.statusCode})`,
          type: SnackType.ERROR,
        });
        this.reset();
      },
    );
    this.emitLoading(false);
  }

  get selected() {
    return Boolean(this.eslValue && this.eslInfo);
  }

  reset() {
    this.eslValue = null;
    this.eslInfo = null;
    this.eslInfos = [];
    this.eslConfigs = [];
  }
}
