
import Vue from 'vue';
import Component from 'vue-class-component';
import EslList from './EslList.vue';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { EslConfig } from '@/shared/store/models/EslConfig';
import { parseEslCode } from '../esl-form.utils';
import { Nullable } from '@/shared/app/app.utils';
import { EslInfo } from '@/shared/store/models/EslInfo';

@Component({ components: { EslList } })
export default class EslSelectionForm extends Vue {
  @Prop()
  value!: Nullable<{ eslConfig: EslConfig; eslInfo: Nullable<EslInfo>; isChild: boolean; parentUseCode: Nullable<string> }>;
  @Prop({ default: () => [] })
  eslConfigs!: EslConfig[];
  @Prop({ default: () => [] })
  eslInfos!: EslInfo[];
  @Prop({ default: 'Code' })
  label!: string;
  @Prop({ default: false })
  clearable!: boolean;

  useCode: Nullable<string> = null;
  searchInput = '';

  @Emit('input')
  select(eslConfig: EslConfig) {
    return eslConfig;
  }

  getTargetsUseCode(labels: string[]) {
    return labels.map((lbl) => this.eslInfos.find(v => v.labelCode === lbl)?.useCode).sort();
  }

  get items() {
    // Filter si searchInput
    let eslConfigs = this.eslConfigs;
    if (this.searchInput)
      eslConfigs = eslConfigs.filter((eslConfig) => eslConfig.useCode.startsWith(this.searchInput.toUpperCase()));

    const orderedEslConfigs = eslConfigs.sort((eslConfigA, eslConfigB) => {
      const parseA = parseEslCode(eslConfigA.useCode);
      const parseB = parseEslCode(eslConfigB.useCode);
      if (!parseA || !parseB) return 0;
      if (parseA.letters > parseB.letters) return 1;
      if (parseB.letters > parseA.letters) return -1;
      if (parseA.number > parseB.number) return 1;
      if (parseB.number > parseA.number) return -1;
      return 0;
    });

    return orderedEslConfigs.map((eslConfig) => {
      return {
        text: eslConfig.useCode,
        value: {
          eslConfig,
          eslInfo: this.eslInfos.find((eslInfo) => eslInfo.labelCode === eslConfig.labelCode),
          isChild: this.eslInfos.filter(i => i.cloneEnable === true).map((eslInfo) => eslInfo.cloneTargets).flat().includes(eslConfig.labelCode),
          parentUseCode: this.eslInfos.find((eslInfo) => eslInfo.cloneEnable === true && eslInfo.cloneTargets?.includes(eslConfig.labelCode))?.labelCode
        }
      };
    });
  }

  @Watch('value')
  valueWatcher(value: Nullable<EslConfig>) {
    if (!value) this.clear();
  }

  public clear() {
    this.useCode = null;
    this.searchInput = '';
  }
}
